'use client';
import { getExternalLink } from 'core/BasicImage/basic-image.util';
import { sanitize } from 'isomorphic-dompurify';
import Image from 'next/image';
import React, { useRef } from 'react';
import { useMedia } from 'react-use';

import cn from './style.module.sass';

export type CurrentListItem = {
    newImg?: string;
    oldImg?: string;
    icons?: IconsItems[];
    video?: string;
    title: string;
    description: string;
    titlesNew?: TitlesItems[];
    index?: number;
    id?: string;
};

type TitlesItems = {
    title?: string;
};

type IconsItems = {
    icon?: string;
    width?: number;
    height?: number;
};

export default function NewProject({
    title,
    description,
    newImg,
    oldImg,
    video,
    icons,
    titlesNew,
    index,
}: CurrentListItem) {
    const isMobile = useMedia('(max-width: 750px)', true);
    const ref = useRef<HTMLVideoElement | null>(null);

    // function getIconWidth(src: string) {
    //     if (src === '/images/svg/cases-list-icons/ruward.svg') return 84;
    //     return 48;
    // }

    const stopMovie = () => {
        ref?.current?.pause();
    };

    const playMovie = () => {
        ref.current?.play().catch(console.log);
    };

    return (
        <div className={cn['new-project']}>
            <div className={cn['new-project__item']} onMouseOver={playMovie} onMouseOut={stopMovie}>
                {video && !isMobile ? (
                    <div className={cn['new-project__imageContainer']}>
                        <video
                            ref={ref}
                            muted={true}
                            playsInline={true}
                            loop={true}
                            className={cn['new-project__video']}
                        >
                            <source
                                src={`${process.env.NEXT_PUBLIC_CONTENT_URL}${video}`}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                ) : (
                    <div className={cn.imageContainer}>
                        <Image
                            src={getExternalLink(newImg ? newImg : (oldImg as string))}
                            alt="case-img"
                            className={newImg ? cn.imageNew : cn.imageOld}
                            width={300}
                            height={400}
                            sizes="100vw"
                            loading={index === 0 ? 'eager' : 'lazy'}
                            priority={index === 0}
                        />
                    </div>
                )}

                <div className={cn['new-project__block']}>
                    {titlesNew && titlesNew.length > 0 && (
                        <div className={cn['new-project__icons']}>
                            <div className={cn['new-project__ListNew']}>
                                {titlesNew &&
                                    titlesNew.map(item => (
                                        <div
                                            key={item.title}
                                            className={cn['new-project__titleNew']}
                                        >
                                            {item.title}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {icons && icons.length > 0 && (
                        <div className={cn['new-project__icons']}>
                            {icons.map(item => {
                                return (
                                    <div key={item.icon} className={cn['new-project__icon']}>
                                        <Image
                                            // width={getIconWidth(item.icon as string)}

                                            {...(item.width
                                                ? { width: item.width }
                                                : { width: '48' })}
                                            {...(item.height
                                                ? { height: item.height }
                                                : { height: '48' })}
                                            src={getExternalLink(item.icon as string)}
                                            alt="icon"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div
                        dangerouslySetInnerHTML={{ __html: sanitize(title) }}
                        className={cn['new-project__title']}
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                        className={cn['new-project__description']}
                    />
                </div>
            </div>
        </div>
    );
}
